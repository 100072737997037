import {defaults} from "lodash";
import type {Dispatch} from "react";
import * as Storage from "@atg-shared/storage";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import type {ImageRef} from "atg-ui-image/domain/image";
import {frameAction, broadcastAction} from "atg-store-addons";

import type {LOGIN_FINISHED} from "./userActionTypes";
import {
    RECEIVE_USER,
    RECEIVE_LOCAL_USER,
    RECEIVE_USER_FAILED,
    LOGOUT_USER,
    LOGOUT_INITIATED,
    LOGOUT_FINALIZE,
    LOGOUT_FINISHED,
    SET_REMEMBER_USERNAME,
    SET_SHOW_BALANCE,
    UPDATE_SHOW_BALANCE,
    SET_FINGERPRINT_VERIFICATION,
    FETCH_USER,
    FETCH_BALANCE,
    RECEIVE_BALANCE,
    RECEIVE_BALANCE_ERROR,
    FETCH_USER_IF_AUTHORIZED,
    SET_NEXT_POSSIBLE_LOGIN,
    REDUCE_SCOPE,
    USER_AVATAR_UPDATED,
    SET_REMEMBERED_USERNAME,
    USER_AVATAR_REMOVED,
} from "./userActionTypes";
import type {AuthorizedUser} from "./user.types";
import {USER_STORAGE_KEY} from "./index";

export const USER_LOCAL_ATTRIBUTES = [
    "username",
    "rememberUsername",
    "rememberedUsername",
    "showBalance",
    "fingerprintVerification",
    "displayName",
    "loggedIn",
    "firstName",
    "lastName",
    "verificationCodeActive",
    "subscriptionType",
    "contactInfo",
];

export type LogoutOptions = {
    soft?: boolean;
    logoutUrl?: string;
    vertical?: string;
};

export type FetchBalanceOptions = {
    requireAuth?: boolean;
};

export type UserPayload = {
    user: AuthorizedUser;
};

export type ReceiveUserAction = {
    type: typeof RECEIVE_USER;
    payload: UserPayload;
};

type ReceiveLocalUserAction = {
    type: typeof RECEIVE_LOCAL_USER;
    payload: UserPayload;
};

export type ReceiveUserFailedAction = {
    type: typeof RECEIVE_USER_FAILED;
    payload: AtgRequestError;
};

export type LoginFinishedAction = {
    type: typeof LOGIN_FINISHED;
    payload: {
        rememberedUsername?: string;
        loginData: {
            roles: string[];
            status: string;
            user: AuthorizedUser;
        };
    };
};

export type LogoutUserAction = {
    type: typeof LOGOUT_USER;
    payload: LogoutOptions;
};

export type LogoutInitiatedAction = {
    type: typeof LOGOUT_INITIATED;
};

export type FinalizeLogoutAction = {
    type: typeof LOGOUT_FINALIZE;
    payload: LogoutOptions;
};

export type LogoutFinishedAction = {
    type: typeof LOGOUT_FINISHED;
};

type FetchUserAction = {
    type: typeof FETCH_USER;
};

export type FetchBalanceAction = {
    type: typeof FETCH_BALANCE;
    payload: FetchBalanceOptions;
};

export type BalanceInfo = {
    amount: number;
    timestamp: string;
};

type ReceiveBalanceAction = {
    type: typeof RECEIVE_BALANCE;
    payload: BalanceInfo;
};

type ReceiveBalanceErrorAction = {
    type: typeof RECEIVE_BALANCE_ERROR;
};

type RememberUsernameStatusAction = {
    type: typeof SET_REMEMBER_USERNAME;
    payload: {
        status: boolean;
    };
};

type SetRememberedUsernameAction = {
    type: typeof SET_REMEMBERED_USERNAME;
    payload: {
        userName: string;
    };
};

type NextPossibleLogin = {
    type: typeof SET_NEXT_POSSIBLE_LOGIN;
    payload: {
        timestamp: string;
    };
};

export type SetShowBalanceAction = {
    type: typeof SET_SHOW_BALANCE;
    payload: {
        status: boolean;
    };
};

export type UpdateShowBalanceAction = {
    type: typeof UPDATE_SHOW_BALANCE;
    payload: {
        status: boolean;
    };
};

export type SetFingerprintVerificationAction = {
    type: typeof SET_FINGERPRINT_VERIFICATION;
    payload: any;
};

export type FetchUserIfAuthorizedAction = {
    type: typeof FETCH_USER_IF_AUTHORIZED;
};

export type ReduceScopeAction = {
    type: typeof REDUCE_SCOPE;
};

export type UpdateUserAvatarAction = {
    type: typeof USER_AVATAR_UPDATED;
    payload: {
        avatarRef?: ImageRef;
    };
};

export type RemoveUserAvatarAction = {
    type: typeof USER_AVATAR_REMOVED;
};

export type UserAction =
    | ReceiveUserAction
    | ReceiveLocalUserAction
    | ReceiveUserFailedAction
    | LogoutUserAction
    | FinalizeLogoutAction
    | FetchUserAction
    | FetchBalanceAction
    | ReceiveBalanceAction
    | ReceiveBalanceErrorAction
    | RememberUsernameStatusAction
    | SetRememberedUsernameAction
    | NextPossibleLogin
    | SetShowBalanceAction
    | UpdateShowBalanceAction
    | LogoutFinishedAction
    | FetchUserIfAuthorizedAction
    | ReduceScopeAction
    | UpdateUserAvatarAction
    | RemoveUserAvatarAction
    | LoginFinishedAction
    | SetFingerprintVerificationAction;

export const receiveUser = (user: AuthorizedUser) =>
    broadcastAction({
        type: RECEIVE_USER,
        payload: {user},
    });

const receiveLocalUser = (user: AuthorizedUser) => ({
    type: RECEIVE_LOCAL_USER,
    payload: {user},
});

export const receiveUserFailed = (error: AtgRequestError) => ({
    type: RECEIVE_USER_FAILED,
    payload: error,
});

export const logoutUser = (options: LogoutOptions = {}): LogoutUserAction =>
    frameAction({
        type: LOGOUT_USER,
        payload: options,
    });

export const logoutInitiated = (): LogoutInitiatedAction =>
    broadcastAction({
        type: LOGOUT_INITIATED,
    });

export const finalizeLogout = (
    vertical?: string | object,
    options: LogoutOptions = {},
) => ({
    type: LOGOUT_FINALIZE,
    payload: {vertical, ...options},
});

export const logoutFinished = (): LogoutFinishedAction =>
    broadcastAction({
        type: LOGOUT_FINISHED,
    });

export const fetchUser = (): FetchUserAction => frameAction({type: FETCH_USER});

export const fetchBalance = (options: FetchBalanceOptions = {}): FetchBalanceAction =>
    frameAction({
        type: FETCH_BALANCE,
        payload: options,
    });

export const receiveBalance = (payload: BalanceInfo): ReceiveBalanceAction =>
    broadcastAction({
        type: RECEIVE_BALANCE,
        payload,
    });

export const receiveBalanceError = (): ReceiveBalanceErrorAction =>
    broadcastAction({
        type: RECEIVE_BALANCE_ERROR,
    });

export const setNextPossibleLogin = (timestamp: string): NextPossibleLogin => ({
    type: SET_NEXT_POSSIBLE_LOGIN,
    payload: {timestamp},
});

export const setRememberUserName = (status: boolean): RememberUsernameStatusAction => ({
    type: SET_REMEMBER_USERNAME,
    payload: {status},
});

export const setRememberedUserName = (userName: string): SetRememberedUsernameAction => ({
    type: SET_REMEMBERED_USERNAME,
    payload: {userName},
});

export const setShowBalance = (status: boolean): SetShowBalanceAction =>
    frameAction({
        type: SET_SHOW_BALANCE,
        payload: {status},
    });

export const updateShowBalance = (status: boolean): UpdateShowBalanceAction =>
    broadcastAction({
        type: UPDATE_SHOW_BALANCE,
        payload: {status},
    });

export const toggleFingerprintVerification = () => (dispatch: any, getState: any) =>
    dispatch({
        type: SET_FINGERPRINT_VERIFICATION,
        payload: !getState().user.fingerprintVerification,
    });

export const fetchUserIfAuthorized = (): FetchUserIfAuthorizedAction => ({
    type: FETCH_USER_IF_AUTHORIZED,
});

const resetUserProps = USER_LOCAL_ATTRIBUTES.reduce(
    (props, prop) => ({...props, [prop]: undefined}),
    {},
);

export const reduceScope = (): ReduceScopeAction => ({
    type: REDUCE_SCOPE,
});

export const loadLocalUser = () => (dispatch: Dispatch<any>) => {
    const storedAttributes = Storage.getItem(USER_STORAGE_KEY);
    if (storedAttributes) {
        const user = JSON.parse(storedAttributes);
        dispatch(receiveLocalUser(defaults(user, resetUserProps)));
    }
};

export const updateUserAvatar = (avatarRef?: ImageRef): UpdateUserAvatarAction =>
    frameAction({
        type: USER_AVATAR_UPDATED,
        payload: {avatarRef},
    });

export const removeUserAvatar = (): RemoveUserAvatarAction =>
    frameAction({
        type: USER_AVATAR_REMOVED,
    });