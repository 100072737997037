// These should be the ones available in the environment yaml files found in
// /packages/atgse/dev-server/dev_environment_variables/

export const loginTimeLimit = "loginTimeLimit";
export const reduxLogger = "reduxLogger";
export const sportUseProdData = "sportUseProdData";
export const sportUseProdCloudfront = "sportUseProdCloudfront";
export const sportUsePopularMatchesOverride = "sportUsePopularMatchesOverride";
export const shortRealityCheck = "shortRealityCheck";
export const scopeDebugger = "scopeDebugger";

export const reduxDevTools = "reduxDevTools";

/**
 * The trace functionality in redux dev tools affects performance, thus it's optional.
 */
export const activateReduxTrace = "activateReduxTrace";

/**
 * A tool to help go into the limbo mode: the mode where the
 * frontend thinks that the user is logged in but it's not on the backend
 */
export const limboDebugger = "limboDebugger";

export const personalPush = "personalPush";
export const pushNotification = "pushNotification";

export const radarDebugger = "radarDebugger";

/**
 * @deprecated DO NOT USE! This feature flag is kept temporarily only to ensure backwards compatibility with newer version of server together with older versions of horse microFE
 * TODO: remove once horse has been released with a version that no longer references this feature toggle and there is NO RISK of rollback
 *  A toggle to enable the new startlist (will be placed on the starlist)
 */
export const newStartlist = "newStartlist";

/**
 *  A toggle to enable the betting trends in the startlist
 */
export const bettingTrends = "bettingTrends";

/**
 * [ATGSEDIG-37991] netLossLimits for horse and sports
 */
export const rgsLimitForHorseAndSports = "rgsLimitForHorseAndSports";

/**
 * Enable self-exclusions RGS endpoint
 * @deprecated DO NOT USE! This feature flag is kept temporarily only to ensure backwards compatibility with newer version of server together with older versions of client microFE:s
 * TODO: remove once all microFE:s have been released with a version that no longer references this feature toggle
 */
export const selfExclusionsRGS = "selfExclusionsRGS";

/**
 * [ATGSEDIG-37767] change RGS net loss limit endpoint
 * @deprecated DO NOT USE! This feature flag is kept temporarily only to ensure backwards compatibility with newer version of server together with older versions of client microFE:s
 * TODO: remove once all microFE:s have been released with a version that no longer references this feature toggle
 */
export const newRgsLimitService = "newRgsLimitService";

/**
 * add a possibility to enable apollo dev tools in qa or even prod
 */
export const apolloDevTools = "apolloDevTools";

/**
 * [ATGSEDIG-37887] enable or disable kycQuestionnaire
 */
export const kycQuestionnaire = "kycQuestionnaire";

/**
 * The big migration from the old HBH rest way to the new CBH graphql way to place and view bets. It involves changes in "mina spel" page and in the placement of a bet.
 */
export const varenne = "varenne";

/**
 * Description text on the Game Initiator page in shops
 */
export const descriptionGiShops = "descriptionGiShops";

export const abTestOverlay = "abTestOverlay";

export const shopVarenneReceipt = "shopVarenneReceipt";

/**
 * Enable experimental category system for play
 */
export const playExperimental = "playExperimental";

/*
 * Inline reply form in a message in my messages
 */
export const inboxInlineMessageForm = "inboxInlineMessageForm";

/**
 * Mock requests with MSW
 */
export const mockRequestsWithMSW = "mockRequestsWithMSW";

/**
 * Enable debugger for horse mina spel.
 */
export const minaSpelDebugger = "minaSpelDebugger";

/**
 * Enable route for julkalender page.
 */
export const julkalender = "julkalender";

/**
 * Session timer
 */
export const sessionTimer = "sessionTimer";

/**
 * Shorten login reminder snackbar from 35 min -> 10 min
 */
export const shortLoginReminder = "shortLoginReminder";

/*
 * Enable route for Horse Of The Year page (/aretshast)
 */
export const horseOfTheYearPage = "horseOfTheYearPage";

/*
 * Enable detachedMiniPlayer for play vertical
 */
export const loseMiniPlayer = "loseMiniPlayer";

/*
 * Enable route for MyAtg microFE
 */
export const myAtg = "myAtg";

/*
 * Enable route for project "Framtidens Butik" (/discovery)
 */
export const framtidensButik = "framtidensButik";

/*
 * Enable latest version of aws serverless image handler
 */
export const newImageHandler = "newImageHandler";

/*
 * Enable the new version of the shop overview page
 */
export const newShopOverviewPage = "newShopOverviewPage";

/*
 * Enable the new version of shop search.
 * This merges /andelsspel and /butiker into one page.
 */
export const newShopSearchPage = "newShopSearchPage";

/*
 * Avoid permanent redirect from atg.se to Tillsammans
 */
export const avoidPermanentTillsammansRedirect = "avoidPermanentTillsammansRedirect";

/*
 * Turn off tillsammans push
 */
export const tillsammansPush = "tillsammansPush";

/*
 * Enable NPS survey to be shown in Tillsammans
 */
export const npsEnabled = "npsEnabled";

/*
 * Enable 'Speltips' in tillsammans starlists
 *
 * @deprecated all features behind ths flag is now released and this will be removed in a near future
 */
export const tillsammansSpeltips = "tillsammansSpeltips";

/**
 * Enable new UX for the Tillsammans search page
 */
export const tillsammansNewSearch = "tillsammansNewSearch";

/**
 * Enable new UX for the Tillsammans search page
 */
export const tillsammansShowSharedBets = "tillsammansShowSharedBets";

/*
 * Grouping the games of a certain game type in shopshares game selector
 */
export const groupGamesByType = "groupGamesByType";

/*
 * Enable to save last known vertical to localstorage
 */
export const lastVertical = "lastVertical";

/*
 * Enable to trigger the new welcome modal/drawer for registering customers
 */
export const welcomeNewCustomer = "welcomeNewCustomer";

/**
 * Enable UserSegment field on ScheduledHero content type from Contentful.
 * @deprecated DO NOT USE! This feature flag is kept temporarily only to ensure backwards compatibility with newer version of server together with older versions of client microFE:s
 * TODO: remove once all microFE:s have been released with a version that no longer references this feature toggle
 */
export const scheduledHeroUserSegments = "scheduledHeroUserSegments";

/**
 * ATGSEDIG-56398 Enable to use new rcs endpoint for Optins.
 * @deprecated DO NOT USE! This feature flag is kept temporarily only to ensure backwards compatibility with newer version of server together with older versions of client microFE:s
 * TODO: remove once all microFE:s have been released with a version that no longer references this feature toggle
 */
export const rcsOptins = "rcsOptins";

/**
 * @deprecated DO NOT USE! This feature flag is kept temporarily only to ensure backwards compatibility with newer version of server together with older versions of client microFE:s
 * TODO: remove once all microFE:s have been released with a version that no longer references this feature toggle
 */
export const newJackpot = "newJackpot";

/*
 * Enables the new vertical site navigation for desktop and mobile
 */
export const newVerticalNavbar = "newVerticalNavbar";

/*
 * Enables the toggle for the elli assistant AI in horse
 */
export const elliAssistant = "elliAssistant";

/*
 * Enable route for payment microFE (/payment)
 */
export const paymentMicroFe = "paymentMicroFe";

/*
 * Enable route for rg microFE (/rg)
 */
export const rgMicroFe = "rgMicroFe";

/*
 * Enables the toggle for the new mini player
 */
export const newMiniPlayer = "newMiniPlayer";

/*
 * Enables the toggle for the new mini player
 */
export const closeMenuOnVerticalClick = "closeMenuOnVerticalClick";

/*
 * Enable to keep save last known vertical to localstorage only if it's Tillsammans
 */
export const lastVerticalTillsammans = "lastVerticalTillsammans";

/**
 * Enable new API driven Curity flow (replacing iframe)
 */
export const newCurityFlow = "newCurityFlow";

/**
 * Enable daily5 (name might change in the future)
 */
export const daily5 = "daily5";

/*
 * Enables the old top menu in mobile
 */
export const mobileTopMenu = "mobileTopMenu";

/*
 * Enables the toggle for the new play top section
 */
export const newPlayTopSection = "newPlayTopSection";

/*
 * Enables the toggle for the new search bar in the news archive page (/nyheter)
 */
export const newsArchiveSearch = "newsArchiveSearch";

/*
 * Enables the toggle for using the max stake per system for the shopshare bets
 */
export const useMaxStakePerSystem = "useMaxStakePerSystem";

/** *************************************************************************
 *                                                                          *
 *     Enables the toggle for the context using Enhanced live reducer       *
 *                                                                          *
 * ************************************************************************ */
export const liveChannelsContext = "liveChannelsContext";

/*
 * Enables the toggle for using the deposit v2 endpoint for credit card
 */
export const depositV2CreditCard = "depositV2CreditCard";

/*
 *  Enables the admin tool to generate custom testing links for Elli Assistant
 */
export const elliAssistantAdmin = "elliAssistantAdmin";

/*
 * Enables the toggle for the new micro frontend video player
 */
export const microFrontendVideoPlayer = "microFrontendVideoPlayer";

/*
 * Enables the toggle for the new atg header menu
 */
export const newHeaderMenu = "newHeaderMenu";

/*
 * Enables the toggle for micro frontend navbar
 */
export const navbarMicroFE = "navbarMicroFE";

/*
 * Enables the toggle for the new harry boy page
 */
export const newHarryPage = "newHarryPage";

/*
 * Enables the use of the finnish haapi client id
 */
export const haapiClientIdFinland = "haapiClientIdFinland";

/*
 * Enables the toggle for using the cancel and refund feature as admin in shopshare
 */
export const cancelAndRefundShopshare = "cancelAndRefundShopshare";
