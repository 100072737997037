import type {LazyStore} from "@atg-shared/lazy-store";
import {runLazySaga} from "@atg-shared/lazy-store";
import {fetchSaga} from "@atg-shared/fetch-redux";
import {realityCheckSaga} from "@atg-responsible-gambling-shared/reality-check-domain";
import {userGamblingSummarySaga} from "@atg-responsible-gambling-shared/user-gambling-summary-domain";

export default function rootSaga(store: LazyStore<any, any>) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, realityCheckSaga);
    runLazySaga(store, userGamblingSummarySaga);
}
