import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";
import {store as rgStore} from "./store";

serverLogPlugin(log, "rg");
log.setLevel("warn");
configureStoreForAuthentication(rgStore);
configureEmotionCache("rg");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

export const DepositBudgetUpdateModal = () =>
    import(
        /* webpackChunkName: "DepositBudgetUpdateModal" */ "./components/DepositBudgetUpdateModal"
    );

export const RealityCheckModal = () =>
    import(/* webpackChunkName: "RealityCheckModal" */ "./components/RealityCheckModal");

export const UserReachedTimeLimitModal = () =>
    import(
        /* webpackChunkName: "UserReachedTimeLimitModal" */ "./components/UserReachedTimeLimitModal"
    );

export const LogoutUserFifteenMinutesModal = () =>
    import(
        /* webpackChunkName: "LogoutUserFifteenMinutesModal" */ "./components/LogoutUserFifteenMinutesModal"
    );

export const UserGamblingSummaryModal = () =>
    import(
        /* webpackChunkName: "LazyUserGamblingSummaryModal" */ "./components/UserGamblingSummaryModal"
    );

export const LogoutUserOnTimeLimitReachedModal = () =>
    import(
        /* webpackChunkName: "LogoutUserOnTimeLimitReachedModal" */ "./components/LogoutUserOnTimeLimitReachedModal"
    );
